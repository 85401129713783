@import 'base';

html { font-size: 80%; box-sizing: border-box; }
body {
    color: #000 !important;
    background: #fff !important;
    padding-top: 0;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

body::after {
    display: block;
    content: 'Sorry, printing is not allowed.';
    font-size: 3em;
}

.l-Site,
#BetterNavigator {
    display: none;
}
